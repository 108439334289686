import React from 'react';

import { PhotoHeader } from '@lerna-monorepo/common/components/photoHeader/photoHeader.component';
import { PromoTextContent } from '@lerna-monorepo/common/components/promoTextContent/promoTextContent.component';
import { ImagesSectionContent } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.component';
import { TestimonialsSlider } from '@lerna-monorepo/common/components/testimonialsSlider/testimonialsSlider.component';
import { ImagesSectionContentLayoutType } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.enums';
import { ImagesSectionContentData } from '@lerna-monorepo/common/components/imagesSectionContent/imagesSectionContent.types';
import { DynamicContentComponent } from '@lerna-monorepo/common/components/dynamicContentComponent/dynamicContentComponent.component';
import { renderWhenTrue } from '@lerna-monorepo/common/utils';
import { useScrollToSection } from '@lerna-monorepo/common/utils/useScrollToSection';

import {
  ImagesSectionContentContainer,
  DynamicContentComponentContainer,
} from './aboutUs.styled';
import { AboutUsPageData } from './aboutUs.types';
import { Color } from '@lerna-monorepo/common/theme';

const AboutUsPage: React.FC<AboutUsPageData> = (pageData: AboutUsPageData) => {
  const { data, testimonials, currentLanguage } = pageData.pageContext;
  const { handleScroll, sectionRef } = useScrollToSection();
  const {
    headerContent,
    promoTextContent,
    imagesSectionContent,
    ourHistorySection,
  } = data;

  const renderImagesSectionContent = renderWhenTrue(() =>
    imagesSectionContent.map(
      (imageSection: ImagesSectionContentData, index: number) => (
        <ImagesSectionContent
          key={imageSection.headerContent}
          buttonLabel={imageSection.buttonLabel}
          image={imageSection.image}
          icon={imageSection.icon}
          headerContent={imageSection.headerContent}
          positionType={imageSection.positionType}
          internalPage={imageSection.internalPage}
          externalPage={imageSection.externalPage}
          currentLanguage={currentLanguage}
          layoutType={
            index % 2 === 0
              ? ImagesSectionContentLayoutType.ImageContentLayout
              : ImagesSectionContentLayoutType.ContentImageLayout
          }
        />
      )
    )
  );

  return (
    <>
      <PhotoHeader
        subheaderContent={headerContent.subheaderContent}
        headerContent={headerContent.headerContent}
        buttonLabel={headerContent.buttonLabel}
        background={headerContent.background}
        handleScroll={handleScroll}
      />
      <PromoTextContent
        headerContent={promoTextContent.headerContent}
        description={promoTextContent.description}
        promotionImage={promoTextContent.promotionImage}
      />
      <ImagesSectionContentContainer>
        {renderImagesSectionContent(!!imagesSectionContent)}
      </ImagesSectionContentContainer>
      <DynamicContentComponentContainer ref={sectionRef}>
        <DynamicContentComponent
          header={ourHistorySection.sectionHeader}
          dynamicContent={ourHistorySection.dynamicContent}
        />
      </DynamicContentComponentContainer>
      <TestimonialsSlider
        backgroundColor={Color.veryLightGrey2}
        circlesBackgroundColor={Color.white}
        testimonials={testimonials}
        currentLanguage={currentLanguage}
      />
    </>
  );
};

export default AboutUsPage;
